import React from 'react'
import './nav.css'
import {AiOutlineHome, AiOutlineUser, AiOutlineFundProjectionScreen} from 'react-icons/ai'
import {BiBook, BiMessageDetail} from 'react-icons/bi'
import {RiServiceLine} from 'react-icons/ri'
import {Link} from "react-scroll";

const Nav = () => {  
  return (
    <nav>
      <li>
        <Link activeClass="active" offset={-250} spy={true} smooth={true} to="header" duration={100}><AiOutlineHome/></Link>
      </li>
      <li>
        <Link activeClass="active" offset={-50} spy={true} smooth={true} to="about" duration={100}><AiOutlineUser/></Link>
      </li>
      <li>
        <Link activeClass="active" offset={-50} spy={true} smooth={true} to="experience" duration={100}><BiBook/></Link>
      </li>
      <li>
        <Link activeClass="active" offset={-50} spy={true} smooth={true} to="services" duration={100}><RiServiceLine/></Link>
      </li>
      <li>
        <Link activeClass="active" offset={-50} spy={true} smooth={true} to="portfolio" duration={100}><AiOutlineFundProjectionScreen/></Link>
      </li>
      <li>
        <Link activeClass="active" offset={-250} spy={true} smooth={true} to="contact" duration={100}><BiMessageDetail/></Link>
      </li>
    </nav>
  )
}

export default Nav