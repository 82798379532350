import React from 'react'
import './footer.css'
import {FaFacebookF, FaLinkedin} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {FaTwitter} from 'react-icons/fa'


const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <a href="#" className='footer__logo'>Dhiraj Hirani</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://facebook.com/DhirajHirani" target="_blank"><FaFacebookF /></a>
        <a href="https://instagram.com/DhirajHirani" target="_blank"><FiInstagram /></a>
        <a href="https://twitter.com/DhirajHirani" target="_blank"><FaTwitter /></a>
        <a href="https://www.linkedin.com/in/dhiraj-hirani-7057341b/" target="_blank"><FaLinkedin /></a>
      </div>
      
      <div className="footer__copyright">
        <small>Copyright &copy; {currentYear} Dhiraj Hirani. All Rights Reserved</small>
      </div>

    </footer>
  )
}

export default Footer