import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className='service'>
          <div className="service__head">
            <h3>Web Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Using HTML, JavaScript and CSS to bring concepts to life.</li>
            <li>
            <span><BiCheck className='service__list-icon' /></span>Developing a clean user interface.</li>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Implement designs on desktop, tablets and mobiles.</li>
          </ul>
        </article>
        {/* END OF UI/UX Design */}
        
        <article className='service'>
          <div className="service__head">
            <h3>Graphic Design</h3>
          </div>

          <ul className='service__list'>
            <li>
            <span><BiCheck className='service__list-icon' /></span>Creating branding designs.</li>
            <li>
            <span><BiCheck className='service__list-icon' /></span>UX designs for a user interface on websites.</li>
            <li>
            <span><BiCheck className='service__list-icon' /></span>Print Designs.</li>
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}


        <article className='service'>
          <div className="service__head">
            <h3>Programming</h3>
          </div>

          <ul className='service__list'>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Mobile app development</li>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Desktop application development</li>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Web application development.</li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}

      </div>
    </section>
  )
}

export default Services