import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import {FaGooglePlay, FaAppStoreIos} from 'react-icons/fa'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG1} alt="Portfolio Image 1"/>
          </div>

          <h3>The Daily Darshan Application</h3>
          <div className="portfolio__item-desc">
            <h5 className='text-light'>The Daily Darshan App allows users to view over 75 different Hindu Temples worldwide for religious purposes.</h5>
          </div>
          <div className="portfolio__item-cta">
            <a href="https://play.google.com/store/apps/details?id=com.phonegap.dailydarshan&hl=en_GB&gl=US" className='btn' target='_blank'><FaGooglePlay/></a>
            <a href="https://apps.apple.com/in/app/dailydarshan/id1190679543" className='btn' target='_blank'><FaAppStoreIos/></a>
            <a href='https://www.youtube.com/watch?v=UpAm5x8sYSs' className='btn btn-primary' target='_blank'>Demo</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG2} alt="Portfolio Image 2"/>
          </div>

          <h3>The Siyani Family Tree Website</h3>
          <div className="portfolio__item-desc">
            <h5 className='text-light'>A website I developed which holds my Family Tree. This website incorporates user/pass login interface, API's, 
            Bootstrap Framework and a Back-End DB.</h5>
          </div>
          <div className="portfolio__item-cta">
            <a href="https://github.com/dhirajhirani1/Siyani" className='btn' target='_blank'>GitHub</a>
            <a href='http://www.siyani.co.uk/' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG3} alt="Portfolio Image 3"/>
          </div>

          <h3>Web Application Database</h3>
          <div className="portfolio__item-desc">
            <h5 className='text-light'>Implemented an automated directory database for decodes and exchange data via web application which was used by over 300 BT users.</h5>
          </div>
          <div className="portfolio__item-cta">
            <a href='' className='btn disabled-link'>Upon Request</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG4} alt="Portfolio Image 4"/>
          </div>

          <h3>Photoshop Designs</h3>
          <div className="portfolio__item-desc">
            <h5 className='text-light'>Designing multiple prints which includes engagement and wedding ceremony invitations, web covers and business cards (with QR codes).</h5>
          </div>
          <div className="portfolio__item-cta">
            <a href='' className='btn disabled-link'>Upon Request</a>
          </div>
        </article>

      </div>
    </section>
  )
}

export default Portfolio