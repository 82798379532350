import React from 'react'
import {BsLinkedin, BsGithub, BsInstagram} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href='https://www.linkedin.com/in/dhiraj-hirani-7057341b/' target='_blank'><BsLinkedin/></a>
        <a href='https://github.com/dhirajhirani1' target='_blank'><BsGithub/></a>
        <a href='https://www.instagram.com/DhirajHirani/' target='_blank'><BsInstagram/></a>
    </div>
  )
}

export default HeaderSocials