import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const about = () => {
  const currentAge = getAge();

  function getAge() {
    var today = new Date();
    var birthDate = new Date("09 30 1995");
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
  }

  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image"/>
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">

            <article className="about__card">
              <FaAward className="about-icon"/>
              <h5>Experience</h5>
              <small>8+ Years Working</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about-icon"/>
              <h5>Projects</h5>
              <small>30+</small>
            </article>
          </div>

          <p>
            Hi, my name is Dhiraj Hirani, and I am a {currentAge}-year-old Front-End Web Developer from Cardiff. It's been 4 years working as a web developer for BT 
            and I've become versatile in web and desktop application development. I've been developing and improving my design skills from a young age,
            making websites, learning Photoshop and Video Editing applications to developing an app the Android and iOS Platform.
          </p>

          <a href="#contact" className='btn btn-primary'>Lets Talk</a>

        </div>
      </div>

    </section>
  )
}

export default about